import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import AppContext from '../../lib/context';

export default function ProtectedRoute({ roles, children, ...rest }) {
  const context = useContext(AppContext);
  const user = context.getUser();
  const userRoles = user ? user.roles : [];
  const hasPermission = roles.every(requiredRole => userRoles.includes(requiredRole));

  return (
    <Route {...rest}>
      {hasPermission ? children : <Typography variant="h5">401: Unauthorized</Typography>}
    </Route>
  );
}
