import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  header: {
    textAlign: 'center'
  },
  media: {
    height: 200,
    filter: 'sepia(10%)'
  },
  content: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.primary.main
  }
}));

export default function PromoListItem({ documentInfo }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={'/images/flyers/' + documentInfo.imageFileName}
      />
      <CardContent className={classes.content}>
        <Typography gutterBottom variant="h5" component="h2" align="center">
          {documentInfo.title}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button size="small" color="secondary" variant="contained" onClick={() => window.open('/docs/flyers/' + documentInfo.fileName, '_blank')}>
            Download
        </Button>
      </CardActions>
    </Card>

  );
}
