import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 0),
    textTransform: 'capitalize'
  },
  card: {
    height: '100%',
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  cardImage: {
    boxSizing: 'border-box',
    objectFit: 'scale-down',
    padding: theme.spacing(2)
  }
}));

export default function ProductListItem(props) {
  const { item } = props;
  const classes = useStyles();
  const imageUrl = item.imageUrl ? `/images/items/${item.imageUrl}` : `/images/items/unavailable/${item.category ? item.category : ''}_unavailable.jpg`;

  return (
    <Grid className={classes.root} container item md={3}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cardImage}
          component="img"
          alt={`Product - ${item.name}`}
          height="250"
          width="100%"
          image={imageUrl}
        />
        <CardContent>
          <Typography variant="h5" noWrap>{item.name}</Typography>
          <Typography>{`Category: ${item.category}`}</Typography>
          {item.price ? <Typography align="right" variant="h5">${item.price.toFixed(2)}</Typography> : null }
        </CardContent>
      </Card>
    </Grid>
  );
}
