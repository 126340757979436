import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import StyledTableCell from '../../common/styled-table-cell';
import StyledTableRow from '../../common/styled-table-row';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const RemoveButton = withStyles(theme => ({
  contained: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}))(Button);

const EditButton = withStyles(theme => ({
  contained: {
    background: theme.palette.success.main,
    color: 'white'
  }
}))(Button);

export default function InventoryItemTable({ items, setFeaturedAction }) {
  const history = useHistory();

  const tableRows = items.map(item => (
    <StyledTableRow key={item._id}>
      <StyledTableCell>{item.name}</StyledTableCell>
      <StyledTableCell>{item.description}</StyledTableCell>
      <StyledTableCell>{item.brand}</StyledTableCell>
      <StyledTableCell>{item.category}</StyledTableCell>
      <StyledTableCell>{item.imageUrl}</StyledTableCell>
      <StyledTableCell>{`$${item.price.toFixed(2)}`}</StyledTableCell>
      <StyledTableCell>
        <EditButton variant="contained" onClick={() => { history.push(`/admin/inventory/edit/${item._id}`); }} >Update</EditButton>
        {item.featured ? <RemoveButton variant="contained" onClick={() => setFeaturedAction(item._id, false)}>Remove From Featured</RemoveButton>
          : <Button variant="contained" color="primary" onClick={() => setFeaturedAction(item._id, true)}>Add To Featured</Button>}
      </StyledTableCell>
    </StyledTableRow>
  ));

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Brand</StyledTableCell>
            <StyledTableCell>Category</StyledTableCell>
            <StyledTableCell>ImageUrl</StyledTableCell>
            <StyledTableCell>Price</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
