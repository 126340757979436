import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Axios from 'axios';
import ProductCategoryItem from './product-category-item';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2)
    }
  }
}));

export default function ProductCategoryList(props) {
  const classes = useStyles();
  const [categories, setCategories] = React.useState([]);
  React.useEffect(() => {
    Axios.get('/api/products/categories')
      .then(response => {
        const categories = response.data;
        categories.unshift('all products');
        setCategories(categories);
      });
  }, []);

  const categoryListEl = categories.filter(category => category !== null).map(category => <ProductCategoryItem key={category} category={category} />);

  return (
    <React.Fragment>
      <h1>Categories</h1>
      <Grid container direction="row" className={classes.root}>
        {categoryListEl}
      </Grid>
    </React.Fragment>
  );
}
