import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import PaginationActions from '../../common/pagination-actions';
import StyledTableCell from '../../common/styled-table-cell';
import StyledTableRow from '../../common/styled-table-row';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

export default function QuickBooksControlPanel() {
  const [customersImporting, setCustomersImporting] = useState(false);
  const [productsImporting, setProductsImporting] = useState(false);
  const classes = useStyles();
  const [queueItemPage, setQueueItemPage] = useState({
    items: [],
    count: 0,
    page: 0,
    start: 0,
    end: 0
  });
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const pageQuery = query.getAll('page');

  useEffect(() => {
    window.scrollTo(0, 0);
    const token = localStorage.getItem('userToken');
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { page: pageQuery }
      };
      Axios.get('/api/qbsdk/items', config)
        .then(response => {
          setQueueItemPage(response.data);
        });
    }
  }, [window.location.href]);

  const onPageChange = page => {
    query.set('page', page);
    history.push(`${location.pathname}?${query}`);
  };

  const tableRows = queueItemPage.items.map(item => (
    <StyledTableRow key={item._id}>
      <StyledTableCell>{item._id}</StyledTableCell>
      <StyledTableCell>{item.accountId}</StyledTableCell>
      <StyledTableCell>{item.action}</StyledTableCell>
      <StyledTableCell>{item.resourceType}</StyledTableCell>
      <StyledTableCell>{item.resourceId}</StyledTableCell>
      <StyledTableCell>{item.processed.toString()}</StyledTableCell>
      <StyledTableCell>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '11rem' }}>
          {JSON.stringify(item.queryParams)}
        </div>
      </StyledTableCell>
      <StyledTableCell>{item.createdAt}</StyledTableCell>
      <StyledTableCell>{item.updatedAt}</StyledTableCell>
      <StyledTableCell>{item.ticket}</StyledTableCell>
      <StyledTableCell>{item.runOnce.toString()}</StyledTableCell>
    </StyledTableRow>
  ));

  const handleCustomerImport = e => {
    setCustomersImporting(true);
    const token = localStorage.getItem('userToken');
    if (token) {
      Axios.post('/api/users/customers/import', {}, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
        });
    }
  };

  const handleInventoryImport = e => {
    setProductsImporting(true);
    const token = localStorage.getItem('userToken');
    if (token) {
      Axios.post('/api/products/import', {}, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
        });
    }
  };

  const customerImportButton = (
    <Button variant="contained" color="primary" onClick={handleCustomerImport} disabled={customersImporting}>
      <PeopleIcon />
      Import Customers
    </Button>
  );

  const inventoryImportButton = (
    <Button variant="contained" color="primary" onClick={handleInventoryImport} disabled={productsImporting}>
      <AssignmentIcon />
      Import Products
    </Button>
  );

  return (
    <div>
      <Typography component="h1" variant="h4" className={classes.title}>QuickBooks Integration Control Panel</Typography>
      <Grid container>
        <Grid item md={2}>
          {customerImportButton}
        </Grid>
        <Grid item md={2}>
          {inventoryImportButton}
        </Grid>
      </Grid>
      <Divider className={classes.divider}/>
      <Typography component="h1" variant="h5" className={classes.title}>QuickBooks Queue Items</Typography>
      <PaginationActions
        start={queueItemPage.start}
        end={queueItemPage.end}
        count={queueItemPage.count}
        page={queueItemPage.page}
        nPerPage={queueItemPage.nPerPage}
        pageAction={onPageChange}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>id</StyledTableCell>
              <StyledTableCell>AccountId</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
              <StyledTableCell>ResourceType</StyledTableCell>
              <StyledTableCell>ResourceId</StyledTableCell>
              <StyledTableCell>Processed</StyledTableCell>
              <StyledTableCell>QueryParams</StyledTableCell>
              <StyledTableCell>CreatedAt</StyledTableCell>
              <StyledTableCell>UpdatedAt</StyledTableCell>
              <StyledTableCell>Ticket</StyledTableCell>
              <StyledTableCell>RunOnce</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationActions
        start={queueItemPage.start}
        end={queueItemPage.end}
        count={queueItemPage.count}
        page={queueItemPage.page}
        nPerPage={queueItemPage.nPerPage}
        pageAction={onPageChange}
      />
    </div>
  );
}
