import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import { List, ListItem, ListSubheader, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ProductListItem from '../products/product-list-item';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0)
  },
  featuredProducts: {
    padding: theme.spacing(1, 1)
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  subheader: {
    background: theme.palette.primary.main,
    color: 'white'
  }
}));

export default function HomePage(props) {
  const classes = useStyles();
  const history = useHistory();
  const [categories, setCategories] = React.useState([]);
  const [featuredProducts, setFeaturedProducts] = React.useState([]);

  React.useEffect(() => {
    Axios.get('/api/products/categories')
      .then(response => {
        const categories = response.data;
        categories.unshift('all products');
        setCategories(categories);
      });
  }, []);

  React.useEffect(() => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    Axios.get('/api/products/featured?limit=12', config)
      .then(response => {
        const items = response.data;
        setFeaturedProducts(items);
      });
  }, []);

  const categoryNavItems = categories.map(category => {
    const queryParamString = category === 'all products' ? '' : `?category=${category}`;

    return (
      <ListItem key={category} button onClick={() => history.push(`/products/list${queryParamString}`)}>
        <Typography className={classes.capitalize}>{category}</Typography>
      </ListItem>
    );
  });

  const productElements = featuredProducts.map(item => {
    return (
      <ProductListItem key={item.name} item={item} />
    );
  });

  return (
    <Grid container className={classes.root}>
      <Grid container item md={2}>
        <Paper square style={{ width: '100%' }}>
          <List
            component="nav"
            subheader={
              <ListSubheader component="div" disableSticky className={classes.subheader}>
                Product Categories
              </ListSubheader>
            }>
            {categoryNavItems}
          </List>
        </Paper>
      </Grid>
      <Grid item md={10} className={classes.featuredProducts}>
        <Typography variant="h4">Featured Items</Typography>
        <Grid container>
          {featuredProducts.length > 0 ? productElements : (<Typography variant="body1">There are no featured items at the moment. Please check back later!</Typography>)}
        </Grid>

      </Grid>
    </Grid>
  );
}
