import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ProductCategoryList from './product-category';
import ProductList from './product-list';

export default function ProductsPage(props) {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <ProductCategoryList />
        </Route>
        <Route path={`${path}/list`}>
          <ProductList />
        </Route>
      </Switch>
    </div>
  );
}
