import React from 'react';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
    paddingRight: theme.spacing(1)
  }
}));

export default function ProductListFilter(props) {
  const classes = useStyles();
  const [searchInput, setSearchInput] = React.useState(props.value);

  const handleSubmit = event => {
    event.preventDefault();
    props.submitAction(searchInput);
  };

  const handleReset = event => {
    event.preventDefault();
    setSearchInput('');
    props.resetAction();
  };

  const handleChange = event => {
    setSearchInput(event.target.value);
  };

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <TextField
          id="search"
          variant="outlined"
          placeholder="Search Products"
          fullWidth
          onChange={handleChange}
          value={searchInput}
          InputProps={{
            startAdornment: (
              < InputAdornment position="start" >
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        <Grid container>
          <Grid container item md={6}>
            <Button fullWidth variant="contained" color="primary" type="submit">Search</Button>
          </Grid>
          <Grid container item md={6}>
            <Button fullWidth variant="contained" type="reset">Reset</Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
