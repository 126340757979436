import React from 'react';
import Header from './header';
import Container from '@material-ui/core/Container';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppContext from '../lib/context';
import { createMuiTheme, ThemeProvider, responsiveFontSizes, makeStyles } from '@material-ui/core/styles';
import { blue, blueGrey, green, red } from '@material-ui/core/colors';
import ProductsPage from './products/products';
import CssBaseline from '@material-ui/core/CssBaseline';
import HomePage from './home/home';
import PromoPage from './promo/promo';
import Login from './auth/login';
import Axios from 'axios';
import PasswordResetStart from './auth/password-reset-start';
import PasswordResetEnd from './auth/password-reset-end';
import ProtectedRoute from './auth/protected-route';
import AdminHome from './admin/admin-home';
import About from './about/about';
import Footer from './footer';
import NewUserSetup from './auth/new-user-setup';

let defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: blue[700]
    },
    secondary: {
      main: blueGrey[900]
    },
    error: {
      main: red[500]
    },
    success: {
      main: green[500]
    }
  }
});
defaultTheme = responsiveFontSizes(defaultTheme);

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh'
  }
}));

export default function App(props) {
  const classes = useStyles();
  const [user, setUser] = React.useState(null);

  const login = user => {
    const { data: userData, accessToken } = user;
    localStorage.setItem('userToken', accessToken);
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('userToken');
    setUser(null);
  };

  const contextValue = {
    businessAddress: '17813 S Main St. Ste 118, Gardena, CA 90248',
    businessPhone: '(310) 965-0909',
    getUser: () => user,
    login,
    logout
  };

  React.useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      Axios.get('/api/refresh', { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          login(response.data);
        })
        .catch(err => {
          if (err.response.status === 401) {
            logout();
          }
        });
    }
  }, [user && user.email]);

  return (
    <AppContext.Provider value={contextValue}>
      <CssBaseline />
      <ThemeProvider theme={defaultTheme}>
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/password-reset/start">
              <PasswordResetStart />
            </Route>
            <Route path="/password-reset/end">
              <PasswordResetEnd />
            </Route>
            <Route path="/new-user-setup">
              <NewUserSetup />
            </Route>
            <ProtectedRoute path="/admin" roles={['administrator']}>
              <AdminHome />
            </ProtectedRoute>
            <Route path="/">
              <Header />
              <Container variant="fluid" className={classes.root}>
                <Switch>
                  <Route exact path="/">
                    <HomePage />
                  </Route>
                  <Route path="/products">
                    <ProductsPage />
                  </Route>
                  <Route path="/promo">
                    <PromoPage />
                  </Route>
                  <Route path="/about">
                    <About />
                  </Route>
                  <Route path="*">
                    404 Page not found
                  </Route>
                </Switch>
              </Container>
              <Footer />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </AppContext.Provider>
  );
}
