import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import StyledTableCell from '../../common/styled-table-cell';
import StyledTableRow from '../../common/styled-table-row';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const RemoveButton = withStyles(theme => ({
  contained: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}))(Button);

const EditButton = withStyles(theme => ({
  contained: {
    background: theme.palette.success.main,
    color: 'white'
  }
}))(Button);

export default function PromoFlyerTable({ promoFlyers, setEnabledAction }) {
  const history = useHistory();

  const tableRows = promoFlyers.map(flyer => (
    <StyledTableRow key={flyer._id}>
      <StyledTableCell>{flyer.title}</StyledTableCell>
      <StyledTableCell>{flyer.fileName}</StyledTableCell>
      <StyledTableCell>{flyer.description}</StyledTableCell>
      <StyledTableCell>{flyer.enabled + ''}</StyledTableCell>
      <StyledTableCell>
        <EditButton variant="contained" onClick={() => { history.push(`/admin/promo/flyer/edit/${flyer._id}`); }} >Update</EditButton>
        {flyer.enabled ? <RemoveButton variant="contained" onClick={() => setEnabledAction(flyer._id, false)}>Disable</RemoveButton>
          : <Button variant="contained" color="primary" onClick={() => setEnabledAction(flyer._id, true)}>Enable</Button>}
      </StyledTableCell>
    </StyledTableRow>
  ));

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell>FilePath</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Enabled</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
