import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import PaginationActions from '../../common/pagination-actions';
import Divider from '@material-ui/core/Divider';
import PromoFlyerTable from './promo-flyer-table';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

export default function PromoControlPanel() {
  const classes = useStyles();
  const history = useHistory();
  const [flyerPage, setFlyerPage] = useState({
    flyers: [],
    count: 0,
    page: 0,
    start: 0,
    end: 0
  });
  const [enabledFlyers, setEnabledFlyers] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const pageQuery = query.getAll('page');
  const searchQuery = query.get('search') || '';

  const getFlyerPage = () => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    config.params = {
      page: pageQuery,
      search: searchQuery
    };
    Axios.get('/api/promo/flyer', config)
      .then(response => {
        setFlyerPage(response.data);
      });
  };

  const getEnabledFlyers = () => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    Axios.get('/api/promo/flyer/enabled', config)
      .then(response => {
        setEnabledFlyers(response.data);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getFlyerPage();
  }, [window.location.href]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getEnabledFlyers();
  }, []);

  const onSetEnabled = (id, enabled) => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    const data = {
      enabled
    };
    Axios.put(`/api/promo/flyer/set-enabled/${id}`, data, config)
      .then(response => {
        getEnabledFlyers();
        getFlyerPage();
      });
  };

  const onPageChange = page => {
    query.set('page', page);
    history.push(`${location.pathname}?${query}`);
  };

  return (
    <div>
      <Typography component="h1" variant="h4" className={classes.title}>Promo Control Panel</Typography>
      <Button variant="contained" color="primary" onClick={() => history.push('/admin/promo/flyer/edit')}>
        Add New Flyer
      </Button>
      <Divider className={classes.divider} />
      <Typography component="h1" variant="h5" className={classes.title}>Enabled Promotional Flyers</Typography>
      {enabledFlyers.length > 0 ? (
        <PromoFlyerTable promoFlyers={enabledFlyers} setEnabledAction={onSetEnabled} />
      )
        : (<Typography variant="body1">There are no enabled flyers</Typography>)}
      <Divider className={classes.divider} />
      <Typography component="h1" variant="h5" className={classes.title}>All Promotional Flyers</Typography>
      <PaginationActions
        start={flyerPage.start}
        end={flyerPage.end}
        count={flyerPage.count}
        page={flyerPage.page}
        nPerPage={flyerPage.nPerPage}
        pageAction={onPageChange}
      />
      <PromoFlyerTable promoFlyers={flyerPage.flyers} setEnabledAction={onSetEnabled} />
      <PaginationActions
        start={flyerPage.start}
        end={flyerPage.end}
        count={flyerPage.count}
        page={flyerPage.page}
        nPerPage={flyerPage.nPerPage}
        pageAction={onPageChange}
      />
    </div>
  );
}
