import React from 'react';
import Axios from 'axios';
import Box from '@material-ui/core/Box';
import { makeStyles, FormGroup, Typography, FormControlLabel, Checkbox } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  ({
    root: {
      width: '100%'
    },
    capitalize: {
      textTransform: 'capitalize'
    }
  })
);

export default function ProductListFilterCategory(props) {
  const classes = useStyles();
  const selectedCategories = props.categories || [];
  const [categories, setCategories] = React.useState([]);
  React.useEffect(() => {
    Axios.get('/api/products/categories')
      .then(response => {
        const categories = response.data;
        categories.unshift('all products');
        setCategories(categories);
      });
  }, []);

  const listItems = categories.map(category => {
    return (
      <FormControlLabel
        className={classes.capitalize}
        key={category}
        control={
          <Checkbox
            checked={selectedCategories.includes(category) || (selectedCategories.length === 0 && category === 'all products')}
            name="category"
            color="primary"
            onClick={() => props.categoryCheckAction(category)}
          />
        }
        label={category}
      />
    );
  });

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Typography variant="h6">By Category</Typography>
      <FormGroup>
        {listItems}
      </FormGroup>
    </Box>
  );
}
