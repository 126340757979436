import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, TextField, Link, Button } from '@material-ui/core';
import AppContext from '../../lib/context';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Axios from 'axios';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    objectFit: 'cover',
    width: '100%'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function Login() {
  const context = useContext(AppContext);
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    Axios.post('/api/login', {
      email,
      password
    })
      .then(response => {
        context.login(response.data);
        setHasError(false);
        history.push('/');
      })
      .catch(err => {
        const { status } = err.response;
        setHasError(status === 404 || status === 401);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <img className={classes.logo} src="/images/ATM_web_logo-04.png"></img>
        <Typography component="h1" variant="h5">
        Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            error={hasError}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            type="email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            error={hasError}
            helperText={hasError ? 'Invalid email and/or password!' : ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
          Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to='/password-reset/start'>
              Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to='/about#signup'>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
