import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%'
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  inactiveLink: {
    '&:hover': {
      cursor: 'pointer'
    },
    textTransform: 'capitalize'
  },
  formElement: {
    marginBottom: theme.spacing(2)
  }
}));

export default function UserCustomerRegister() {
  const classes = useStyles();
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const [customerIndexInput, setCustomerIndexInput] = useState('');
  const [customerNameInput, setCustomerNameInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const fetchCustomers = () => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    Axios.get('/api/customers/list', config)
      .then(response => {
        setCustomers(response.data);
      });
  };

  const createUser = data => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    Axios.post('/api/register-existing-customer/start', data, config)
      .then(response => {
        history.push('/admin/users');
      }).catch(error => {
        if (error.response.status === 409) {
          setErrorMessage('ERROR: This email is already registered.');
        } else {
          setErrorMessage('ERROR: Whoops, something went wrong!');
        }
      });
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const newUser = {
      email: emailInput,
      customerId: customers[customerIndexInput].id
    };
    createUser(newUser);
  };

  const items = customers.map((customer, i) => {
    return (
      <MenuItem key={customer.id} value={i}>
        { customer.details }
      </MenuItem>
    );
  });

  const companyNameInputLabel = customers[customerIndexInput] ? `Confirm Customer Name (${customers[customerIndexInput].companyName})` : 'Confirm Customer Name';

  return (
    <div>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}>
        <Link component={RouterLink} to='/admin/users'>UserControlPanel</Link>
        <Link className={classes.inactiveLink} color='inherit'>New User</Link>
      </Breadcrumbs>
      <Typography variant="h4">
        Register Customer
      </Typography>
      <Divider
        className={classes.divider} />
      <form encType="multipart/form-data" className={classes.form} onSubmit={handleSubmit}>
        <Grid container>
          <Grid container item display="flex" alignItems="center" md={4}>
            <TextField
              type="email"
              value={emailInput}
              onChange={e => setEmailInput(e.target.value)}
              required
              id="email"
              label="E-mail"
              fullWidth
              variant="outlined"
              className={classes.formElement}
            />
            <Select
              disabled={customers.length <= 0}
              className={classes.formElement}
              fullWidth
              value={customerIndexInput}
              onChange={e => setCustomerIndexInput(e.target.value)}
            >
              {items}
            </Select>
            <TextField
              value={customerNameInput}
              onChange={e => setCustomerNameInput(e.target.value)}
              required
              id="customerNameInput"
              label={companyNameInputLabel}
              fullWidth
              variant="outlined"
              className={classes.formElement}
              disabled={!customers[customerIndexInput]}
            />
            <Button variant="contained" color="primary" type="submit" disabled={!customers[customerIndexInput] || customers[customerIndexInput].companyName !== customerNameInput}>
              Create User
            </Button>
            { errorMessage ? (
              <Typography color="error" variant="body1" align="center">
                {errorMessage}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
