import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

export default function UserControlPanel() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div>
      <Typography component="h1" variant="h4" className={classes.title}>User Control Panel</Typography>
      <Divider className={classes.divider}/>
      <Typography component="h1" variant="h5" className={classes.title}>Create a User</Typography>
      <Button variant="contained" color="primary" onClick={() => history.push('/admin/users/customer/register')}>
        Register New User
      </Button>
    </div>

  );
}
