import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';

export default function PaginationActions(props) {
  const { start, end, page, nPerPage, count, pageAction } = props;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography>{`${start} - ${end} of ${count} results`}</Typography>
      </Box>
      <Box>
        <IconButton
          onClick={() => pageAction(0)}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={() => pageAction(page - 1)}
          disabled={page === 0}
          aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={() => pageAction(page + 1)}
          disabled={page >= Math.ceil(count / nPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={() => pageAction(Math.max(0, Math.ceil(count / nPerPage) - 1))}
          disabled={page >= Math.ceil(count / nPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
