import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink, useHistory } from 'react-router-dom';
import Axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckBox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%'
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  itemImage: {
    boxSizing: 'border-box',
    objectFit: 'scale-down',
    maxWidth: '300px'
  },
  inactiveLink: {
    '&:hover': {
      cursor: 'pointer'
    },
    textTransform: 'capitalize'
  },
  formElement: {
    marginBottom: theme.spacing(2)
  }
}));

export default function PromoFlyerEdit() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [flyer, setFlyer] = useState({});
  const [titleInput, setTitleInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const [enabledInput, setEnabledInput] = useState(false);

  const hasFlyer = () => Object.keys(flyer).length > 0;

  const fetchPromoFlyer = () => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    if (id) {
      Axios.get(`/api/promo/flyer/${id}`, config)
        .then(response => {
          const flyer = response.data;
          setFlyer(flyer);
          setTitleInput(flyer.title);
          setDescriptionInput(flyer.description);
          setEnabledInput(flyer.enabled);
        });
    }
  };

  useEffect(() => {
    if (id != null) fetchPromoFlyer();
  }, []);

  const uploadFlyer = data => {
    const token = localStorage.getItem('userToken');
    const config = { headers: { 'Content-type': 'multipart/form-data' } };
    if (token) config.headers.Authorization = `Bearer ${token}`;
    Axios.post('/api/promo/flyer',
      data,
      config)
      .then(response => {
        history.push('/admin/promo');
      });
  };

  const editFlyer = data => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    Axios.put(`/api/promo/flyer/${flyer._id}`, data, config)
      .then(response => {
        history.push('/admin/promo');
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (hasFlyer()) {
      editFlyer({
        title: titleInput,
        description: descriptionInput,
        enabled: enabledInput
      });
    } else {
      const flyerFile = e.target.elements.file?.files[0];
      const data = new FormData();
      data.append('file', flyerFile);
      data.append('title', titleInput);
      data.append('description', descriptionInput);
      data.append('enabled', enabledInput);
      uploadFlyer(data);
    }

  };

  return (
    <div>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}>
        <Link component={RouterLink} to='/admin/promo'>PromoControlPanel</Link>
        { hasFlyer() ? (
          <Link
            className={classes.inactiveLink}
            color='inherit'>{flyer.title || ''}</Link>)
          : (<Link
            className={classes.inactiveLink}
            color='inherit'>New Flyer</Link>)}
      </Breadcrumbs>
      <Typography variant="h4">
        { hasFlyer() ? `Edit Flyer: ${flyer.title}` : 'New Flyer'}
      </Typography>
      <Divider
        className={classes.divider} />
      {hasFlyer() ? (
        <img
          className={classes.itemImage}
          src={`/images/flyers/${flyer.imageFileName}`}
          alt="Item Image" />) : null}
      <form encType="multipart/form-data" className={classes.form} onSubmit={handleSubmit}>
        <Grid container>
          <Grid container item display="flex" alignItems="center" md={4}>
            { hasFlyer() ? (
              <TextField
                label="Original File Name"
                value={flyer.originalFileName}
                fullWidth
                variant="outlined"
                disabled
                className={classes.formElement}
              />
            ) : (<Input
              name="file"
              type="file"
              inputProps={{ accept: 'application/pdf' }}
              required
              className={classes.formElement} />)}
            <FormControlLabel
              control={
                <CheckBox
                  checked={enabledInput}
                  onChange={e => setEnabledInput(e.target.checked)}
                  color="primary" />}
              label="Enabled"
              className={classes.formElement}
            />
            <TextField
              value={titleInput}
              onChange={e => setTitleInput(e.target.value)}
              required
              id="title"
              label="Title"
              rows={3}
              fullWidth
              variant="outlined"
              className={classes.formElement}
            />
            <TextField
              value={descriptionInput}
              onChange={e => setDescriptionInput(e.target.value)}
              required
              id="description"
              label="Description"
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              className={classes.formElement}
            />
            <Button variant="contained" color="primary" type="submit">
              {hasFlyer() ? 'Edit' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
