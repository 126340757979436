import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Axios from 'axios';
import PromoListItem from './promo-list-item';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0)
  },
  title: {
    paddingBottom: theme.spacing(4)
  }
}));

export default function Promo() {
  const classes = useStyles();
  const [flyers, setFlyers] = useState([]);

  const getPromoFlyers = () => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    Axios.get('/api/promo/flyer/enabled', config)
      .then(response => {
        setFlyers(response.data);
      });
  };

  useEffect(() => {
    getPromoFlyers();
  }, []);

  const promoElements = flyers.map(flyer => {
    return (
      <Grid key={flyer._id} item md={3}>
        <PromoListItem documentInfo={flyer} />
      </Grid>
    );
  });

  return (
    <div className={classes.root}>
      <Typography variant="h3" align="center" className={classes.title}>Current Promotional Flyers</Typography>
      <Grid container spacing={1}>
        {promoElements}
      </Grid>
    </div>
  );
}
