import React from 'react';
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import Axios from 'axios';
import Grid from '@material-ui/core/Grid';
import ProductListItem from './product-list-item';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import PaginationActions from '../common/pagination-actions';
import ProductListFilterSearch from './product-list-filter-search';
import ProductListFilterCategory from './product-list-filter-category';

const useStyles = makeStyles(theme => ({
  inactiveLink: {
    '&:hover': {
      cursor: 'pointer'
    },
    textTransform: 'capitalize'
  },
  fullWidth: {
    width: '100%'
  }
}));

export default function ProductList(props) {
  const [productPage, setProductPage] = React.useState({
    items: [],
    count: 0,
    page: 0,
    start: 0,
    end: 0
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const classes = useStyles();
  const searchQuery = query.get('search') || '';
  const categoryQuery = query.getAll('category');
  const pageQuery = query.getAll('page');
  const limitQuery = query.getAll('limit');
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    config.params = {
      search: searchQuery,
      category: categoryQuery,
      page: pageQuery,
      limit: limitQuery
    };
    Axios.get('/api/products', config)
      .then(response => {
        setProductPage(response.data);
      });
  }, [window.location.href]);

  function getCurrentCrumb(searchQuery, categoryQuery) {
    if (searchQuery.length === 0 && categoryQuery.length === 0) return null;
    const label = (searchQuery.length > 1 || categoryQuery.length > 1) ? 'Custom Search' : categoryQuery[0];
    return (<Link className={classes.inactiveLink} color='inherit'>{label}</Link>);
  }

  const productElements = productPage.items.map(item => {
    return (
      <ProductListItem key={item.name} item={item}/>
    );
  });

  const onPageChange = page => {
    query.set('page', page);
    history.push(`${location.pathname}?${query}`);
  };

  const onCategoryToggle = category => {
    query.delete('category');
    query.set('page', 0);

    if (category !== 'all products') {
      const cIndex = categoryQuery.indexOf(category);
      if (cIndex === -1) {
        categoryQuery.push(category);
      } else {
        categoryQuery.splice(cIndex, 1);
      }
      categoryQuery.forEach(category => query.append('category', category));
    }

    history.push(`${location.pathname}?${query}`);
  };

  const onFilterSearchSubmit = filterInput => {
    query.set('search', filterInput);
    query.delete('page');
    history.push(`${location.pathname}?${query}`);
  };

  const onFilterSearchReset = () => {
    query.delete('search');
    history.push(`${location.pathname}?${query}`);
  };

  return (
    <React.Fragment>
      <h1>Product List</h1>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        <Link component={RouterLink} to='/'>Home</Link>
        <Link component={RouterLink} to='/products'>Products</Link>
        <Link component={RouterLink} to='/products/list'>All</Link>
        {getCurrentCrumb(searchQuery, categoryQuery)}
      </Breadcrumbs>
      <Grid container>
        <Grid container item md={3} display="flex" direction="column">
          <ProductListFilterSearch submitAction={onFilterSearchSubmit} resetAction={onFilterSearchReset} value={searchQuery}/>
          <ProductListFilterCategory categories={categoryQuery} categoryCheckAction={onCategoryToggle}/>
        </Grid>
        <Grid container item md={9}>
          <Box>
            <Box className={classes.fullWidth}>
              <PaginationActions
                start={productPage.start}
                end={productPage.end}
                count={productPage.count}
                page={productPage.page}
                nPerPage={productPage.nPerPage}
                pageAction={onPageChange} />
            </Box>
            <Grid container>
              {productElements}
            </Grid>
            <Box className={classes.fullWidth}>
              <PaginationActions
                start={productPage.start}
                end={productPage.end}
                count={productPage.count}
                page={productPage.page}
                nPerPage={productPage.nPerPage}
                pageAction={onPageChange} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
