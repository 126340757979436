
export default function validatePassword(password) {
  /*  Password must be:
   *  - Between 6 & 30 characters
   *  - Must contain at least 1 lower-case, upper-case, number
   *  - Some special characters are not allowed
   */
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{6,30}$/;
  return password.match(passwordRegex);
}
