import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    overflow: 'none'
  },
  logoContainer: {
    margin: theme.spacing(4, 0)
  },
  logo: {
    objectFit: 'contain',
    width: '90%',
    padding: theme.spacing(2, 0)
  },
  divider: {
    margin: theme.spacing(5, 0)
  },
  section: {
    minHeight: '500px'
  },
  header: {
    paddingBottom: theme.spacing(4)
  }
}));

export default function About() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Grid container justify="space-evenly" className={classes.logoContainer}>
          <Grid container item md={3} sm={12} alignContent="center">
            <img src="/images/atm/muchuck-logo.png" alt="Muchuck Logo" className={classes.logo} />
          </Grid>
          <Grid container item md={3} sm={12} alignContent="center">
            <img src="/images/atm/atm-main-logo.png" alt="ATM Logo" className={classes.logo} />
          </Grid>
        </Grid>
        <Typography variant="h4" align="center" gutterBottom>Let us supply the parts so you can focus on what matters!</Typography>
      </div>
      <div className={classes.section} id="us">
        <Typography component="h1" variant="h3" className={classes.header}>About Us</Typography>
        <Typography component="p" variant="body1">
          For over 15 years, Auto Town Mayor has been providing local auto shops
          with supplies at competitive prices!
        </Typography>
        <br/>
        <Typography component="p" variant="body1">
          We carry everything from lubricants, transmission fluid, oil filters, cleaners,
          tools, and much more. Also, because of the current pandemic situation, we now carry masks and sanitizer as well.
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          We serve auto shops primarily in the LA/Orange county area but feel free to contact us to see if we can deliver to you.
          We accept orders through phone, fax, or in-person. We usually ship within 1 or 2 days but same day delivery is
          also possible depending on the time and location.
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          If you are interested and would like to be our customer, please contact us at <a href="tel:+13109650909">(310) 965-0909</a>
        </Typography>
      </div>
      <div className={classes.section} id="website">
        <Typography component="h1" variant="h3" className={classes.header}>About The Site</Typography>
        <Typography component="p" variant="body1">
          Welcome to the new website! We are still getting set up so look forward to upcoming features.
        </Typography>
        <br/>
        <Typography component="p" variant="body1">
          Currently, guests are able to view all of our products but logged in users will have access to additional features.
          Logged in users will be able to also see prices for all products and view all of our current promotional flyers/items.
        </Typography>
        <br/>
        <Typography component="p" variant="body1">
          In the near future, we plan to provide features such as ordering items directly through the website and viewing recent orders & balances.
        </Typography>
        <br/>
        <Typography component="p" variant="body1" id="signup">
          If you are a current customer and would like to sign-up for an account, please contact your sales representative. We only need your business name & email to get you started.
        </Typography>
        <br/>
        <Typography component="p" variant="body1">
          Additionally, if you have any questions regarding the website please contact us at <a href="mailto:help.autotownmayor@gmail.com">help.autotownmayor@gmail.com</a>
        </Typography>
      </div>

    </div>
  );
}
