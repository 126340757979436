import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink, useHistory } from 'react-router-dom';
import Axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%'
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  itemImage: {
    boxSizing: 'border-box',
    objectFit: 'scale-down',
    maxWidth: '300px'
  },
  inactiveLink: {
    '&:hover': {
      cursor: 'pointer'
    },
    textTransform: 'capitalize'
  },
  formElement: {
    marginBottom: theme.spacing(2)
  }
}));

function FileInput({ className, label, value, onChange = () => {}, ...rest }) {
  return (
    <Button
      className={className}
      fullWidth
      variant="contained"
      component="label"
    >
      {label}
      <input
        {...rest}
        type="file"
        hidden
        onChange={e => onChange([...e.target.files])} />
    </Button>

  );

}

export default function InventoryItemEdit() {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [inventoryItem, setInventoryItem] = useState({});
  const [categories, setCategories] = useState([]);
  const [imageInput, setImageInput] = useState({});
  const [categoryInput, setCategoryInput] = useState('');
  const [previewImageSrc, setPreviewImageSrc] = useState('');

  useEffect(() => {
    fetchInventoryItem();
  }, []);

  React.useEffect(() => {
    Axios.get('/api/products/categories')
      .then(response => {
        const categories = response.data;
        setCategories(categories);
      });
  }, []);

  const displayImage = () => {
    if (previewImageSrc) return (<img className={`${classes.itemImage} ${classes.formElement}`} src={previewImageSrc} alt="Preview Image" />);
    else if (imageUrl) return (<img className={`${classes.itemImage} ${classes.formElement}`} src={`/images/items/${inventoryItem.imageUrl}`} alt="Item Image" />);
    else return null;
  };

  const fetchInventoryItem = () => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

    Axios.get(`/api/products/${id}`, config)
      .then(response => {
        setInventoryItem(response.data);
      });
  };

  const handleImageFileChange = ([file]) => {
    setImageInput(file);
    const reader = new FileReader();
    reader.onload = e => setPreviewImageSrc(e.target.result);
    reader.readAsDataURL(file);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const data = new FormData();
    if (imageInput) data.append('file', imageInput);
    if (categoryInput) data.append('category', categoryInput);
    updateInventoryItem(data);
  };

  const updateInventoryItem = data => {
    const token = localStorage.getItem('userToken');
    const config = { headers: { 'Content-type': 'multipart/form-data' } };
    if (token) config.headers.Authorization = `Bearer ${token}`;
    Axios.put(`/api/products/${id}`,
      data,
      config)
      .then(response => {
        history.push('/admin/inventory');
      });
  };

  const updatesPending = () => {
    return imageInput.name || categoryInput;
  };

  const { imageUrl, brand, price, description, category } = inventoryItem;

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        <Link component={RouterLink} to='/admin/inventory'>InventoryControlPanel</Link>
        {inventoryItem.name ? <Link className={classes.inactiveLink} color='inherit'>{inventoryItem.name}</Link> : null}
      </Breadcrumbs>
      <Typography variant="h4">Edit Inventory Item: {inventoryItem.name || ''}</Typography>
      <Divider className={classes.divider} />
      <form encType="multipart/form-data" className={classes.form} onSubmit={handleSubmit}>
        <Grid container>
          <Grid container item display="flex" alignItems="center" md={3}>
            {displayImage()}
            <FileInput label="Upload New Image" name="file" className={classes.formElement} accept="image/*" onChange={handleImageFileChange}/>
            {brand != null ? <TextField label="Brand" value={brand} fullWidth variant="outlined" className={classes.formElement} InputProps={{ readOnly: true }} /> : null}
            {price != null ? <TextField label="Price" value={`$${price.toFixed(2)}`} fullWidth variant="outlined" className={classes.formElement} InputProps={{ readOnly: true }} /> : null}
            {description != null ? <TextField label="Description" value={description} fullWidth variant="outlined" className={classes.formElement} InputProps={{ readOnly: true }} multiline rows={3}/> : null}
            {categories.length > 0 ? (
              <FormControl className={classes.formElement} fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={categoryInput || category || ''}
                  onChange={e => setCategoryInput(e.target.value)}
                >
                  {categories.map(category => <MenuItem value={category} key={category}>{category}</MenuItem>)}
                </Select>
              </FormControl>
            ) : null}
            <Button variant="contained" color="primary" type="submit" disabled={!updatesPending()}>
              Save Changes
            </Button>
          </Grid>
        </Grid>

      </form>
    </div>
  );
}
