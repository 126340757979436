import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  card: {
    height: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  cardImage: {
    boxSizing: 'border-box',
    objectFit: 'scale-down',
    padding: theme.spacing(2)
  },
  capitalize: {
    textTransform: 'capitalize'
  }
}));

export default function ProductCategoryItem(props) {
  const history = useHistory();
  const classes = useStyles();
  const categoryName = props.category;
  const imagePath = `/images/items/category/${categoryName}.jpg`;
  const queryParamString = categoryName === 'all products' ? '' : `?category=${categoryName}`;

  return (
    <Grid className={classes.root} item lg={4} sm={6} xs={12}>
      <Card className={classes.card} onClick={() => history.push(`/products/list${queryParamString}`)}>
        <CardActionArea className={classes.fullHeight}>
          <CardMedia
            className={classes.cardImage}
            component="img"
            alt={`Category - ${categoryName}`}
            height="300"
            width="100%"
            image={imagePath}
          />
          <CardContent>
            <Typography className={classes.capitalize} variant="h4">{categoryName}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );

}
