import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    objectFit: 'cover',
    width: '100%'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function PasswordResetStart() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    Axios.post('/api/password-reset/start', { email })
      .then(response => {
        setMessage('Email has been sent to the provided address. Check the email for further instructions.');
      }).catch(() => {
        setMessage('Whoops, something went wrong!');
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const formElement = (
    <form className={classes.form} onSubmit={handleSubmit}>
      { !isLoading ? (
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          type="email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isLoading}
        className={classes.submit}
      >
      Send Password Reset Link
      </Button>
    </form>
  );

  const postSubmitElement = (
    <React.Fragment>
      <Typography variant="body1" align="center">
        {message}
      </Typography>
      <Button
        onClick={() => history.push('/')}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Return to main page
      </Button>
    </React.Fragment>
  );

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <img className={classes.logo} src="/images/ATM_web_logo-04.png"></img>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        {message ? postSubmitElement : formElement}
      </div>
    </Container>
  );
}
