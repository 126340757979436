import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/Place';
import PhoneIcon from '@material-ui/icons/Phone';

import AppContext from '../lib/context';

const useStyles = makeStyles(theme => ({
  root: {

  },
  subHeader: {
    background: 'black',
    color: 'white',
    padding: theme.spacing(1, 0)
  },
  businessLogo: {
    objectFit: 'cover',
    width: '100%'
  },
  businessInfo: {
    marginRight: theme.spacing(2)
  },
  link: {
    color: 'white'
  }
}));

export default function Header(props) {
  const history = useHistory();
  const classes = useStyles();
  const context = useContext(AppContext);
  const isLoggedIn = !!context.getUser();

  return (
    <React.Fragment>
      <Box className={classes.subHeader}>
        <Container variant="fluid">
          <Grid container>
            <Grid container item display="flex" md={8}>
              <Box display="flex" className={classes.businessInfo} alignItems="center">
                <PlaceIcon />
                <Typography component="span">{context.businessAddress}</Typography>
              </Box>
              <Box display="flex" className={classes.businessInfo} alignItems="center">
                <PhoneIcon />
                <Typography component="span">{context.businessPhone}</Typography>
              </Box>
            </Grid>
            <Grid container item display="flex" justify="flex-end" md={4}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={isLoggedIn ? () => { context.logout(); history.push('/'); } : () => history.push('/login')}>
                {isLoggedIn ? 'Log Out' : 'Login'}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <AppBar position="static">
        <Container variant="fluid">
          <Toolbar disableGutters>
            <Grid container item md={6}>
              <Grid container item md={4}>
                <img className={classes.businessLogo} src="/images/ATM_web_logo-04.png"></img>
              </Grid>
            </Grid>
            <Grid container item component="nav" md={6} display="flex" justify="flex-end">
              <Grid container item md={2} justify="center">
                <Button color="inherit" onClick={() => history.push('/')} fullWidth>Home</Button>
              </Grid>
              <Grid container item md={2} justify="center">
                <Button color="inherit" onClick={() => history.push('/products')} fullWidth>Products</Button>
              </Grid>
              {isLoggedIn
                ? <Grid container item md={2} justify="center">
                  <Button color="inherit" onClick={() => history.push('/promo')} fullWidth>Promo</Button>
                </Grid> : null}
              <Grid container item md={2} justify="center">
                <Button color="inherit" onClick={() => history.push('/about')} fullWidth>About</Button>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
}
