import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import validatePassword from '../../lib/validators/password-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    objectFit: 'cover',
    width: '100%'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function PasswordResetEnd() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const resetToken = query.get('token');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    const isValid = validatePassword(password);

    if (isValid) {
      Axios.post('/api/password-reset/end', {
        token: resetToken,
        password
      })
        .then(response => {
          setMessage('Password has successfully been changed!');
        }).catch(error => {
          if (error.response.status === 401) {
            setMessage('Password reset token is either invalid or expired. Please request a new password.');
          } else {
            setMessage('Whoops, something went wrong!');
          }
        }).finally(() => {
          setIsLoading(false);
        });
    } else {
      setPasswordError(true);
    }
  };

  const formElement = (
    <form className={classes.form} onSubmit={handleSubmit}>
      { !isLoading ? (
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          label="New Password"
          type="password"
          name="password"
          autoFocus
          value={password}
          onChange={e => setPassword(e.target.value)}
          error={passwordError}
          helperText={ passwordError ? (
            <>
              <div>Password must:</div>
              <ul>
                <li>be between 6-30 characters</li>
                <li>include at least at least 1 lower-case, upper-case, number</li>
                <li>not include certain special characters</li>
              </ul>
            </>
          ) : null}
        />
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Set Password
      </Button>
    </form>
  );

  const postSubmitElement = (
    <React.Fragment>
      <Typography variant="body1" align="center">
        {message}
      </Typography>
      <Button
        onClick={() => history.push('/login')}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Login
      </Button>
    </React.Fragment>
  );

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <img className={classes.logo} src="/images/ATM_web_logo-04.png"></img>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        {message ? postSubmitElement : formElement}
      </div>
    </Container>
  );
}
