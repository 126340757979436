import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import PaginationActions from '../../common/pagination-actions';
import Divider from '@material-ui/core/Divider';
import ProductListFilter from '../../products/product-list-filter-search';
import InventoryItemTable from './inventory-item-table';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

export default function InventoryControlPanel() {
  const classes = useStyles();
  const history = useHistory();
  const [inventoryPage, setInventoryPage] = useState({
    items: [],
    count: 0,
    page: 0,
    start: 0,
    end: 0
  });
  const [featuredItems, setFeaturedItems] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const pageQuery = query.getAll('page');
  const searchQuery = query.get('search') || '';

  const getProductPage = () => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    config.params = {
      page: pageQuery,
      search: searchQuery
    };
    Axios.get('/api/products', config)
      .then(response => {
        setInventoryPage(response.data);
      });
  };

  const getFeaturedProducts = () => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    Axios.get('/api/products/featured', config)
      .then(response => {
        setFeaturedItems(response.data);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductPage();
  }, [window.location.href]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getFeaturedProducts();
  }, []);

  const onSetFeatured = (id, featured) => {
    const token = localStorage.getItem('userToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    const data = {
      featured
    };
    Axios.put(`/api/products/set-featured/${id}`, data, config)
      .then(response => {
        getFeaturedProducts();
        getProductPage();
      });
  };

  const onPageChange = page => {
    query.set('page', page);
    history.push(`${location.pathname}?${query}`);
  };

  const onFilterSearchSubmit = filterInput => {
    query.set('search', filterInput);
    query.delete('page');
    history.push(`${location.pathname}?${query}`);
  };

  const onFilterSearchReset = () => {
    query.delete('search');
    history.push(`${location.pathname}?${query}`);
  };

  return (
    <div>
      <Typography component="h1" variant="h4" className={classes.title}>Inventory Control Panel</Typography>
      <Divider className={classes.divider}/>
      <Typography component="h1" variant="h5" className={classes.title}>Featured Inventory Items</Typography>
      {featuredItems.length > 0 ? (
        <InventoryItemTable items={featuredItems} setFeaturedAction={onSetFeatured}/>
      )
        : (<Typography variant="body1">There are no featured items</Typography>)}
      <Divider className={classes.divider} />
      <Typography component="h1" variant="h5" className={classes.title}>All Inventory Items</Typography>
      <ProductListFilter submitAction={onFilterSearchSubmit} resetAction={onFilterSearchReset}/>
      <PaginationActions
        start={inventoryPage.start}
        end={inventoryPage.end}
        count={inventoryPage.count}
        page={inventoryPage.page}
        nPerPage={inventoryPage.nPerPage}
        pageAction={onPageChange}
      />
      <InventoryItemTable items={inventoryPage.items} setFeaturedAction={onSetFeatured}/>
      <PaginationActions
        start={inventoryPage.start}
        end={inventoryPage.end}
        count={inventoryPage.count}
        page={inventoryPage.page}
        nPerPage={inventoryPage.nPerPage}
        pageAction={onPageChange}
      />
    </div>
  );
}
